import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import translate from 'services/i18n/translate';
import { UpdatablePreference, GdprUserPreference } from '@wit/vtv-x-foundation-lib';
import * as GdprDuck from 'core/store/ducks/gdpr';
import { ActionCreators as StartUpActionCreators } from 'store/ducks/startUp';
import { StartUpRoutePath } from 'routes/paths';
import { Modal } from '.';

export interface AcceptedPreference {
  preference: UpdatablePreference;
  preferenceValue: GdprUserPreference;
}

let gdprParams: AcceptedPreference | null;

export default function Gdpr(): JSX.Element | null {
  const dispatch = useDispatch();
  const history = useHistory();
  const gdprDocuments = useSelector(GdprDuck.Selectors.getDocumentsToShow);
  const isProcessing = useSelector(GdprDuck.Selectors.isProcessing);
  const isProcessed = useSelector(GdprDuck.Selectors.isProcessed);

  const customClassName = 'modal-gdpr';
  const titleIcon = 'info';

  const [level, setLevel] = useState(-1);
  const [hasStarted, setHasStarted] = useState(false);

  useEffect(() => {
    if (!hasStarted) {
      if (gdprDocuments.notice) {
        setLevel(0);
      } else if (gdprDocuments.consent) {
        setLevel(1);
      }
      setHasStarted(true);
    }
  }, [gdprDocuments.notice, gdprDocuments.consent, hasStarted]);

  useEffect(() => {
    const preference = gdprParams?.preference;
    if (!isProcessing && isProcessed) {
      if (
        (preference === UpdatablePreference.GDPR_NOTICE && !gdprDocuments.consent) ||
        preference === UpdatablePreference.GDPR_CONSENT
      ) {
        dispatch(StartUpActionCreators.gdprFlowDone());
        // Workaround bug #284954
        setTimeout(() => {
          history.replace(StartUpRoutePath.ROOT);
          gdprParams = null;
        }, 0);
      }
    }
  }, [isProcessing, isProcessed, dispatch, gdprDocuments.consent, history]);

  function noticeAcceptedPressed() {
    gdprParams = {
      preference: UpdatablePreference.GDPR_NOTICE,
      preferenceValue: { accepted: true, version: gdprDocuments.notice?.version },
    };
    dispatch(GdprDuck.ActionCreators.updatePreference(gdprParams.preference, gdprParams.preferenceValue));
    if (gdprDocuments.consent) {
      setLevel(1);
    }
  }

  function consentAgreePressed(accept: boolean) {
    gdprParams = {
      preference: UpdatablePreference.GDPR_CONSENT,
      preferenceValue: { accepted: accept, version: gdprDocuments.consent?.version },
    };
    dispatch(GdprDuck.ActionCreators.updatePreference(gdprParams.preference, gdprParams.preferenceValue));
    setLevel(-1);
  }

  switch (level) {
    case 0:
      return (
        <Modal
          title={translate['gdpr.main.notice.title']}
          customClassName={customClassName}
          message={gdprDocuments.notice?.text}
          buttons={[{ id: 'continue', text: translate['modal.button.continue'], onClick: noticeAcceptedPressed }]}
          isShowing
          setIsShowing={() => null}
        />
      );
    case 1:
      return (
        <Modal
          customClassName={customClassName}
          title={translate['gdpr.main.consent.title']}
          subtitle={translate['gdpr.modal.consent.subtitle']}
          message={gdprDocuments.consent?.text}
          buttons={[
            { id: 'agree', text: translate['modal.button.agree'], onClick: () => consentAgreePressed(true) },
            { id: 'disagree', text: translate['modal.button.disagree'], onClick: () => setLevel(2) },
          ]}
          isShowing
          setIsShowing={() => null}
        />
      );
    case 2:
      return (
        <Modal
          customClassName={customClassName}
          message={translate['gdpr.modal.consent.confirmation.text']}
          buttons={[
            { id: 'back', text: translate['modal.button.back'], onClick: () => setLevel(1) },
            { id: 'continue', text: translate['modal.button.continue'], onClick: () => consentAgreePressed(false) },
          ]}
          isShowing
          setIsShowing={() => null}
          titleIcon={titleIcon}
        />
      );
    default:
      return null;
  }
}
