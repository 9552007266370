import React from 'react';

export enum ArrowOrientation {
  LEFT = 'left',
  RIGHT = 'right',
}

interface Props {
  orientation: ArrowOrientation;
  onClick: React.MouseEventHandler<HTMLDivElement> | undefined;
}

function NavigationArrow({ orientation, onClick }: Props) {
  return (
    <div className={`navigation-arrow ${orientation === ArrowOrientation.LEFT ? 'left' : 'right'}`} onClick={onClick}>
      <span className="icon" />
    </div>
  );
}

export default React.memo(NavigationArrow);
