import React, { useCallback, useRef, useState } from 'react';
import { Asset, AssetUtils, FolderType, BookmarkUtils, ContentProps, Ducks } from '@wit/vtv-x-foundation-lib';
import { useSelector } from 'react-redux';
import { StoreState } from 'store/store';
import translate from 'services/i18n/translate';
import ImageLoader from '../ImageLoader';
import ProgressBar from '../ProgressBar';
import Badge from '../Badge';

interface Props {
  asset: Asset;
  onAssetClick: (asset: Asset) => void;
  showSeriesInfo?: boolean;
  ctxId: number | string;
}

const getFolderLabelByFolderType = (asset?: Asset) => {
  if (!asset || !AssetUtils.isFolder(asset)) {
    return undefined;
  }

  switch (asset.folderType) {
    case FolderType.BUNDLE:
      return translate['rail.label.bundle'];
    case FolderType.SUBSCRIPTION:
      return translate['rail.label.subscription'];
    default:
      return undefined;
  }
};

function Landscape({ asset, onAssetClick, showSeriesInfo, ctxId }: Props) {
  const cardId = `${ctxId}-${asset.id}`;
  const bookmark = useSelector((state: StoreState) => Ducks.BookmarksCache.Selectors.getAssetBookmark(state, asset));

  const [customClassName, setCustomClassName] = useState('');
  const [isMouseOver, setIsMouseOver] = useState(false);

  const isEpg = asset && AssetUtils.isEpg(asset);
  const cardImage = asset?.images.card || (isEpg && asset.logo?.color?.uri) || '';
  const isEpisode =
    asset && (AssetUtils.isEpisode(asset) || AssetUtils.isTvAsset(asset)) && AssetUtils.hasValidSeriesId(asset);
  const hasValidSeasonNumber = asset && AssetUtils.hasValidSeasonNumber(asset);
  const hasValidEpisodeNumber = asset && AssetUtils.hasValidEpisodeNumber(asset);
  const title = isEpisode && AssetUtils.isRecording(asset) && !showSeriesInfo ? asset.seriesName : asset.title;
  const folderLabel = getFolderLabelByFolderType(asset);

  const customTimeout = useRef<ReturnType<typeof setTimeout>>();

  const handleMouseEnter = () => {
    setIsMouseOver(true);
    customTimeout.current = setTimeout(() => {
      setCustomClassName('card-full-width');
    }, 1000);
  };

  const handleMouseLeave = () => {
    setIsMouseOver(false);
    if (customTimeout && customTimeout.current) {
      clearTimeout(customTimeout.current);
    }
    setCustomClassName('');
  };

  const getAssetUiWithProgress = useCallback(
    (givenAsset: Asset) => {
      if (bookmark) {
        return BookmarkUtils.getProgressFromBookmark(givenAsset as ContentProps, bookmark);
      }
      return undefined;
    },
    [bookmark]
  );

  return (
    <div
      onClick={() => onAssetClick(asset)}
      className={`dynamic-content-card-container ${customClassName}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      id={`Card___${cardId}`}
    >
      <div className="card-title">
        <div className="text always-visible">
          <h3 id={`Card_title___${cardId}`}>
            {title}
            {showSeriesInfo && isEpisode ? (
              <>
                {' '}
                · {asset && hasValidSeasonNumber && <span> {translate['programInfo.moreepisodes.seasonInitial']}</span>}
                {asset && hasValidSeasonNumber && <span id={`Card_season___${cardId}`}>{asset.seasonNumber}</span>}
                {asset && hasValidEpisodeNumber && <span> {translate['programInfo.moreepisodes.episodeInitial']}</span>}
                {asset && hasValidEpisodeNumber && <span id={`Card_episode___${cardId}`}>{asset.episodeNumber}</span>}
              </>
            ) : null}
          </h3>
        </div>
        {asset.badges && (
          <div className="card-badges">
            {asset.badges.map((badge) => {
              return <Badge badge={badge} bemBlock="Card" bemModifierPrefix={cardId} key={badge.icon} />;
            })}
          </div>
        )}
        <div className="card-title-background landscape" />
      </div>
      {folderLabel ? (
        <h3 id={`Card_folderLabel___${cardId}`} className="card-folder-label">
          {folderLabel}
        </h3>
      ) : null}

      <ImageLoader src={cardImage} bemModifier={cardId} />
      <ProgressBar percentage={getAssetUiWithProgress(asset) || 0} isActive={isMouseOver} bemModifier={cardId} />
    </div>
  );
}

export default React.memo(Landscape);
