import React, { useState, useEffect } from 'react';
import { RailBackgroundImageName } from 'types/components/ColdStart';
import { useSelector } from 'react-redux';
import { Selectors as GDPRSelectors } from 'core/store/ducks/gdpr';
import translate from 'services/i18n/translate';
import GdprConsent from '../GdprConsent';
import ColdStartRail from '../Rail/ColdStartRail';
import Modal from '../Modal';

interface Props {
  railId: string | number;
  railName: string;
  railBackgroundImageName: RailBackgroundImageName;
  railDescription: string;
  onOptInClick?: () => void;
  continueClicked?: () => void;
  optInClicked?: boolean;
}

export default function OptInRail({
  railId,
  railName,
  railBackgroundImageName,
  railDescription,
  onOptInClick,
  continueClicked,
  optInClicked,
}: Props) {
  const [showOptIn, setShowOptIn] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const isGdprProcessed = useSelector(GDPRSelectors.isProcessed);
  const isOptIn = useSelector(GDPRSelectors.hasAgreedToConsent);
  const customClassName = 'modal-gdpr';

  useEffect(() => {
    if (isGdprProcessed && isOptIn) {
      setShowOptIn(false);
      setShowConfirmation(true);
    }
  }, [isGdprProcessed, isOptIn]);

  const onClickPrivacyConcentOptIn = (): void => {
    if (!showOptIn) {
      setShowOptIn(true);
    }
    if (onOptInClick) {
      onOptInClick();
    }
  };

  const modalConfirmationClicked = () => {
    if (continueClicked) {
      continueClicked();
    }
  };

  return (
    <div id={`OptInRail___${railId}`}>
      <ColdStartRail
        railId={railId}
        railName={railName}
        railBackgroundImageName={railBackgroundImageName}
        railDescription={railDescription}
        requiresPrivacyConsent
        onClickPrivacyConcentOptIn={onClickPrivacyConcentOptIn}
        className="no-background"
      />
      {showOptIn && <GdprConsent onConsentRefuse={() => setShowOptIn(false)} />}
      {showConfirmation && optInClicked && (
        <Modal
          customClassName={customClassName}
          title={translate['gdpr.main.consent.title']}
          message={translate['gdpr.modal.consent.agreed.text']}
          buttons={[
            { id: 'continue', text: translate['modal.button.continue'], onClick: () => modalConfirmationClicked() },
          ]}
          isShowing
          setIsShowing={() => null}
        />
      )}
    </div>
  );
}
