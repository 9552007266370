import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { UpdatablePreference } from '@wit/vtv-x-foundation-lib';
import * as GdprDuck from 'core/store/ducks/gdpr';
import translate from 'services/i18n/translate';
import { Modal } from '.';
import { AcceptedPreference } from './Gdpr';

interface Props {
  onConsentRefuse?: () => void;
}

let gdprParams: AcceptedPreference | null;

export default function GdprConsent({ onConsentRefuse }: Props): JSX.Element | null {
  const dispatch = useDispatch();
  const customClassName = 'modal-gdpr';
  const [level, setLevel] = useState(1);
  const gdprDocuments = useSelector(GdprDuck.Selectors.getDocuments);

  function consentAgreePressed(accept: boolean) {
    if (gdprDocuments) {
      gdprParams = {
        preference: UpdatablePreference.GDPR_CONSENT,
        preferenceValue: { accepted: accept, version: gdprDocuments.consent?.version },
      };
      dispatch(GdprDuck.ActionCreators.updatePreference(gdprParams.preference, gdprParams.preferenceValue));
      setLevel(-1);
    }
  }

  const onRefuse = () => {
    consentAgreePressed(false);
    if (onConsentRefuse) {
      onConsentRefuse();
    }
  };

  switch (level) {
    case 1:
      return (
        <Modal
          customClassName={customClassName}
          title={translate['gdpr.main.consent.title']}
          subtitle={translate['gdpr.modal.consent.subtitle']}
          message={(gdprDocuments && gdprDocuments.consent?.text) || ''}
          buttons={[
            { id: 'agree', text: translate['modal.button.agree'], onClick: () => consentAgreePressed(true) },
            { id: 'disagree', text: translate['modal.button.disagree'], onClick: () => setLevel(2) },
          ]}
          isShowing
          setIsShowing={() => null}
        />
      );
    case 2:
      return (
        <Modal
          customClassName={customClassName}
          message={translate['gdpr.modal.consent.confirmation.text']}
          buttons={[
            { id: 'back', text: translate['modal.button.back'], onClick: () => setLevel(1) },
            { id: 'continue', text: translate['modal.button.continue'], onClick: onRefuse },
          ]}
          isShowing
          setIsShowing={() => null}
          titleIcon="info"
        />
      );
    default:
      return null;
  }
}
